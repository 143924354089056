<template>
  <div class="main-layout no-scroll" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div class="content landing-page">
      <div class="center">
        <p class="userExperience">
          {{mobileViewText}}
        </p>
        <p class="install">
          {{ $t("enjoy the best user experience") }}
        </p>
        <p class="download">{{ $t("Download Now") }}</p>
        <div class="android" v-if="!isIOS">
          <ul>
            <li class="app-links">
              <a href="/en" target="_blank">
                <img src="@/assets/icons/google-play.png" alt />
              </a>
            </li> 
          </ul>
          <ul>
            <li class="app-links-huawei">
              <a href="/en" target="_blank">
                <img src="@/assets/icons/huawei.png" alt="" />
              </a>
            </li>
          </ul>

          <!-- <a href="https://noorplay-landing-page.web.app/settings" target="_blank">Already have our app ?</a> -->
        </div>
        <!-- Ios section -->
        <div class="ios" v-if="isIOS">
          <ul>
            <li class="app-links">
              <a
               href="https://ultra-jhakaas-test.web.app/en" 
               target= "_blank">
               <img src="@/assets/icons/appstore.png" alt />
              </a>
            </li>
          </ul>
          <p class="Or">{{ $t("Or") }}</p>
          <p class="subscribe">
            {{ $t("Subscribe using the mobile website") }}
          </p>
          <button class="subsbribePlans" @click="subscribePlans">
            {{ $t("Subscribe Plans") }}
          </button>
        </div>
        <div v-if="this.$i18n.locale === 'mr'" class="arabicCTAB" :dir="localDisplayLang === 'mr' ? 'rtl' : 'rtl'">
          <button @click="switchToInit"><span>या वेबसाइटवर रहा</span></button>
        </div>
         <!-- <button @click="switchToInit">متابعة</button>&nbsp;&nbsp;<span>या वेबसाइटवर रहा</span>
        </div> -->
        <button class="stayOnThisWebsite" @click="switchToInit" v-if="this.$i18n.locale === 'eng'">
          {{ $t("Stay on this website") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
export default {
  data() {
    return {
      localDisplayLang: "eng",
      isIOS: false,
      isSubscribe: false,
      subscribepayload: {},
      isPayment: false,
      subscriberDetailsMobileView: "",
      mobileViewText:"",
      isActiveMobilePlaystore:false,
    };
  },

  computed: {
    ...mapGetters(["appConfig", "subscriberId"]),
  },

  created() {
    localStorage.setItem("showSubscriptionPlans", false);
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    //console.log("local display language", this.localDisplayLang);

    // CheckForIOS is called to check if it is IOS mobile  browser
    this.checkForIOS();
  },
  mounted() {
    //Have to remove few header contents when in mobile view , so calling this event
    eventBus.$emit("hideHeaderOptions");
    this.mobileViewText = this.$t(window.PROVIDER_SETUP_CONFIG.isUser_Experience)
    this.isActiveMobilePlaystore = this.$t(window.PROVIDER_SETUP_CONFIG.isMobileplaystoreEnabled)
  },
  methods: {
    checkForIOS() {
      console.log("check for IOS");
      const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);
      if (
        /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
        (isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 2)
      ) {
        // true for mobile device
        this.isIOS = true;
        //console.log("it is ios");
      } else {
        this.isIOS = false;
        //console.log("it is not ios");
      }
    },
    switchToInit() {
      //console.log("im switching to init");
      localStorage.setItem("isMobile", false);
      let payload = false;
      eventBus.$emit("triggerInitView", payload);
    },
    subscribePlans() {
      //console.log("subscribe popup");
      this.subscriberDetailsMobileView = JSON.parse(localStorage.getItem("subscriberDetails"));
      // console.log("sub det from local", this.subscriberDetailsMobileView);
      if (
        this.subscriberDetailsMobileView &&
        this.subscriberDetailsMobileView.data &&
        this.subscriberDetailsMobileView.data.subscriberid
      ) {
        //console.log("show subscriptions popup==========");
        eventBus.$emit("triggerInitView", payload);
        let payload = { listType: "onlySubscribe" };
        eventBus.$emit("subscribePopup", payload);
      } else {
        //console.log("show auth popup========");
        let payload = {
          state: true,
          formType: "lookup",
          isMobileWeb: true,
        };
        eventBus.$emit("authPopup", payload);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./mobileWeb.scss"
</style>
